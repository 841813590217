import React from 'react';
import GridContainer from "@/components/common/GridContainer";
import Text from "@/components/common/Text";
import styled from "styled-components";
import {getCssSizeValue, getHtmlFromRedactorField, getPaddingsClassFromData} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import BothMaps from "@/components/Maps/BothMaps";
import BranchSelect from "@/components/BranchSelect";
import LinkButton from "@/components/common/Buttons/LinkButton";
import {useIsMobile} from "@/hooks/useDevice";


const Wrapper = styled.div`
    background: ${({theme}) => theme.colors.richBlue};
`

const Grid = styled(GridContainer)`
    width: 100%;
    position: relative;
    row-gap: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        top: var(--padding-top);
        background-color: inherit;
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        row-gap: ${getCssSizeValue(32, BREAKPOINTS.DESKTOP)};
    }
`

const MapContainer = styled.div`
	order: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    grid-column: 1/9;
    background-color: inherit;
    padding-right: ${getCssSizeValue(90, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		order: 1;
        padding: ${getCssSizeValue(64, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(30, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(72, BREAKPOINTS.DESKTOP)};
        grid-column: 1/6;
    }
`

const Info = styled.div`
    grid-column: 1/9;
	color: ${({theme}) => theme.colors.platinum};
	order: 1;
	display: flex;
	flex-direction: column;
	row-gap: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	justify-content: center;

    .description {
		margin-bottom: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		order: 2;
		padding: ${getCssSizeValue(120)} 0;
        grid-column: 7/17;
        row-gap: ${getCssSizeValue(32, BREAKPOINTS.DESKTOP)};

        .header {
            translate: ${getCssSizeValue(32)} 0;
            width: 36%;
        }

        .description {
            .description {
                margin-bottom: unset;
            }
            translate: ${getCssSizeValue(32)} 0;
            width: 76%;
        }

    }

    span.color-red {
        color: ${({theme}) => theme.colors.periwinkle};
        font-weight: 700;
    }
`

const CurrentStateBlock = styled.div`
    width: calc(100% + var(--paddingX));
    display: flex;
    flex-direction: column;
    gap: ${getCssSizeValue(20)};
    color: ${({theme}) => theme.colors.richBlack};
    background: ${({theme}) => theme.colors.periwinkle};
    padding: ${getCssSizeValue(32)} var(--paddingX) ${getCssSizeValue(24)} ${getCssSizeValue(32)};

    .text {
        font-weight: 500;
    }
	
	.select-with-button {
        display: flex;
		justify-content: space-between;
	}
	
	&.full-width{
		order: 3;
        width: calc(100% + var(--paddingX) * 2);
		margin-left: calc(-1 * var(--paddingX));
		padding: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)} var(--paddingX) ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	}
`

function replaceStyleWithClass(input) {
	return input.replace(/<span style="text-decoration: line-through;">/g, '<span class="color-red">');
}

const StatesMapModule = ({data}) => {
	const id = data?.id || ''
	const header = data?.header || '';
	const description = data?.description || ''
	const aboveSelectText = data?.aboveSelectText || ''
	const button = data?.button || {}
	const headerDescription = getHtmlFromRedactorField(replaceStyleWithClass(header))
	const redactorDescription = getHtmlFromRedactorField(description)
	const paddings = getPaddingsClassFromData(data);
	const isMobile = useIsMobile()
	return (
		<Wrapper key={id} className={`${paddings}`}>
			<Grid className="no-margin padding">
				<MapContainer>
					<BothMaps className='simple-map-right'/>
				</MapContainer>
				<Info>
					<Text
						tag="div"
						className="typo-borna typo-borna-40 header"
						dangerouslySetInnerHTML={headerDescription}
					/>
					<Text
						tag="div"
						className="typo-strawford typo-strawford-39 description"
						dangerouslySetInnerHTML={redactorDescription}
					/>
					{!isMobile ?  (
						<CurrentStateBlock>
							<div className='text typo-strawford typo-strawford-84'>{aboveSelectText}</div>
							<div className='select-with-button'>
								<BranchSelect/>
								<LinkButton data={button}/>
							</div>
						</CurrentStateBlock>
					) : (
						<LinkButton data={button}/>
					)}
				</Info>
				{isMobile && (
					<CurrentStateBlock className="full-width col-mobile-8">
						<div className='text typo-strawford typo-strawford-84'>{aboveSelectText}</div>
						<div className='select-with-button'>
							<BranchSelect/>
							{/*<LinkButton data={button}/>*/}
						</div>
					</CurrentStateBlock>
				)}
			</Grid>
		</Wrapper>
	);
};

export default StatesMapModule;
